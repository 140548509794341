<template>
    <div class="detail-wrap">
        <div class="header">
            <div>
                <span>{{ title }}</span>
                <span>处理状态： {{ baseData.closeStatusName }}</span>
                <span><Button type="primary" @click="doClaim"
                        v-if="baseData.closeStatus == '0' && !baseData.claimBy">认领</Button>
                    <Button class="btn" type="primary" @click="doClose"
                        v-if="baseData.closeStatus == '0' && baseData.claimBy">关闭</Button>
                </span>
            </div>
        </div>

        <div class="content">
            <!-- 基础信息 -->
            <sv-card title="基础信息" :showLoading="showLoading">
                <div slot="body">
                    <sv-panel-form ref="baseForm" :labelWidth="130">
                        <item type="row">
                            <item type="col">
                                <item label="反馈单号" type="displayfield" name="id" />
                                <item label="反馈时间" type="displayfield" name="createdOn" />
                                <item label="技术支持联系人" type="displayfield" name="technicianMan" />
                                <item label="技术支持电话" type="displayfield" name="technicianTel" />
                            </item>
                            <item type="col">
                                <item label="经销商简称" type="displayfield" name="dealerAs" />
                                <item label="经销商代码" type="displayfield" name="dealerCode" />
                                <item label="经销商名称" type="displayfield" name="dealerName" />
                            </item>
                        </item>
                    </sv-panel-form>
                </div>
            </sv-card>

            <!-- 车辆信息 -->
            <sv-card title="车辆信息" :showLoading="showLoading">
                <div slot="body">
                    <sv-panel-form ref="carForm" :labelWidth="130">
                        <item type="row">
                            <item type="col">
                                <item label="VIN" type="displayfield" name="vin" />
                            </item>
                            <item type="col">
                                <item label="车型" type="displayfield" name="modelPlatformName" />
                                <item label="生产日期" type="displayfield" name="productDate" />
                                <item label="里程" type="displayfield" name="mileage" />
                                <item label="购车时间" type="displayfield" name="buyDate" />
                            </item>
                        </item>
                    </sv-panel-form>
                </div>
            </sv-card>

            <!-- 故障处理过程 -->
            <sv-card title="故障处理过程" :showLoading="showLoading">
                <div slot="body">
                    <sv-panel-form ref="faultForm" :labelWidth="130">
                        <item type="row">
                            <item type="col">
                                <item label="故障描述" type="displayfield" name="faultDesc" />
                            </item>
                            <item type="col">
                                <item label="系统查询SC" type="displayfield" name="systemSelectSc" />
                                <item label="系统查询SKC" type="displayfield" name="systemSelectSkc" />
                            </item>
                            <item type="col">
                                <item label="日期" type="displayfield" name="faultDealWithDate" />
                                <item label="时间" type="displayfield" name="faultDealWithDatestr" />
                            </item>
                            <item type="col">
                                <item label="描述" type="displayfield" name="processingDesc" />
                            </item>
                        </item>
                    </sv-panel-form>
                </div>
            </sv-card>

            <!-- 诊断仪相关信息 -->
            <sv-card title="诊断仪相关信息" :showLoading="showLoading">
                <div slot="body">
                    <sv-panel-form ref="dsForm" :labelWidth="130">
                        <item type="row">
                            <item type="col">
                                <item label="诊断仪账户" type="displayfield" name="dsAccount" />
                                <item label="诊断注册手机" type="displayfield" name="dsMobile" />
                            </item>
                            <item type="col">
                                <item label="故障码" type="displayfield" name="faultCode" />
                            </item>
                            <item type="col">
                                <item label="原零件号" type="displayfield" name="oldPartCode" />
                                <item label="新零件号" type="displayfield" name="newPartCode" />
                            </item>
                            <item type="col">
                                <item label="原零件/标签图片" span="24" type="image" name="oldpartResIds" />
                            </item>
                            <item type="col">
                                <item label="新零件/标签图片" span="24" type="image" name="newpartResIds" />
                            </item>
                        </item>
                    </sv-panel-form>
                </div>
            </sv-card>

            <!-- 读取PEPSECU信息诊断仪截图: -->
            <sv-card title="读取PEPSECU信息诊断仪截图:" :showLoading="showLoading">
                <div slot="body" style="text-align: left" v-if="baseData.pepsecuResIds">
                    <sv-image
                        v-for="item in baseData.pepsecuResIds"
                        :key="item.path"
                        :width="100"
                        :height="100"
                        :src="item.path"
                        :isShowDownload="false"></sv-image>
                    <div class="footer"></div>
                </div>
                <div v-else>无数据</div>
            </sv-card>

            <!-- 读取防盗匹配相关数据:  -->
            <sv-card title="读取防盗匹配相关数据: :" :showLoading="showLoading">
                <div slot="body">
                    <span>{{ baseData.pepsecuDesc }}</span>
                </div>
            </sv-card>

            <!-- 沟通记录 -->
            <ChatInfo
                :btnDisabled="baseData.closeStatus == '0' && !!baseData.claimBy"
                ref="chatInfo"
                :isEdit="true"
                @submited="loadBaseData"
                :loadUrl="`/consult/ds/feedback/replies?id=${$route.query.id}`"
                submitUrl="/consult/ds/feedback/consult"
                url="/api/consult/res/upload" />
        </div>
    </div>
</template>

<script>
import { request } from '@/network/request';
import ChatInfo from '../common/chatInfo.vue';

export default {
    name: 'M001-1',
    components: {
        ChatInfo
    },
    data() {
        return {
            title: document.title,
            baseData: {},
            showLoading: false
        };
    },

    computed: {},

    created() {
        this.loadBaseData();
    },

    methods: {
        loadBaseData() {
            const url = '/consult/ds/feedback/detail?id=' + this.$route.query.id;
            this.showLoading = true;
            request
                .get(url)
                .then(data => {
                    if (data.success) {
                        const { result } = data;
                        this.baseData = result || {};
                        this.$nextTick(() => {
                            this.$refs.baseForm.setValues(result);
                            this.$refs.carForm.setValues(result.dsFeedbackVin);
                            this.$refs.faultForm.setValues(result);
                            this.$refs.dsForm.setValues(result);
                        });
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });
        },
        doClaim() {
            const url = '/consult/ds/feedback/claim';
            const params = {
                id: this.$route.query.id
            };

            this.showLoading = true;

            request
                .post(url, params)
                .then(data => {
                    if (data.success) {
                        this.$Message.success('认领成功');
                        this.loadBaseData();
                    } else {
                        this.$Message.error(data.message);
                    }
                })

                .finally(() => {
                    this.showLoading = false;
                });
        },
        doClose() {
            const url = '/consult/ds/feedback/close';
            const params = {
                id: this.$route.query.id
            };

            this.showLoading = true;

            request
                .post(url, params)
                .then(data => {
                    if (data.success) {
                        this.$Message.success('关闭成功');
                        this.loadBaseData();
                    } else {
                        this.$Message.error(data.message);
                    }
                })

                .finally(() => {
                    this.showLoading = false;
                });
        }
    }
};
</script>

<style lang="less" scoped>
.detail-wrap {
    height: 100%;
    margin: 0 auto;
    width: 70%;
    min-width: 1100px;
    display: flex;
    flex-direction: column;
    position: relative;

    .header {
        display: flex;
        justify-content: space-between;
        position: relative;
        font-size: 14px;
        color: #4e4e4e;
        height: 34px;
        line-height: 34px;
        padding: 0 6px;
        font-weight: 600;
        background: #e4e4e4;

        span:nth-child(2) {
            position: absolute;
            right: 200px;
            top: 0;
        }

        span:last-child {
            position: absolute;
            right: 20px;
            top: 0;
        }
    }

    .content {
        flex: 1;
        padding: 5px 0;
    }

    /deep/ .ivu-card-head {
        background: #e4e4e4;
    }

    /deep/.displayfield {
        margin-top: 4px;
    }

    /deep/.images-wrap {
        margin-right: 10px;
    }

    /deep/ .image-class {
        height: 100%;
    }
}
</style>
